export const eventsConfig = [
    {
        id: 14,
        eventDay: '09',
        eventDayNumber: 'ВС',
        eventInfoDate: '09 февраля',
        eventInfoTitle: 'Концерт',
        eventDescription: '08 февраля начало в 16-00ч. приглашаем на КОНЦЕРТ "Зимние песни о главном".ВХОД СВОБОДНЫЙ',
        eventCost: 'ОСП ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/песни-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/песни-02.jpg'),
    },
    {
        id: 13,
        eventDay: '08',
        eventDayNumber: 'СБ',
        eventInfoDate: '08 февраля',
        eventInfoTitle: 'Золотые слова Пушкина',
        eventDescription: '08 февраля начало в 12-00ч. приглашаем посетить фестиваль художественного слова "Золотые слова Пушкина".ВХОД СВОБОДНЫЙ',
        eventCost: 'ОСП ДК "Юбилейный"',
        eventImgSrc: require('../../assets/images/eventsImages/Пушкин25-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/Пушкин25-02.jpg'),
    },
    {
        id: 12,
        eventDay: '01',
        eventDayNumber: 'СБ',
        eventInfoDate: '01 февраля',
        eventInfoTitle: 'Читаем рассказы Антоши Чехонте',
        eventDescription: '01 февраля начало в 12-00ч. Ведущий творческий коллектив города Москвы театральный коллектив "Мыслите" читаем рассказы А.П.Чехова. ВХОД СВОБОДНЫЙ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/Чехов-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/Чехов-02.jpg'),
    },
    {
        id: 11,
        eventDay: '31',
        eventDayNumber: 'ПТ',
        eventInfoDate: '31 января',
        eventInfoTitle: 'Обсуждение кинокартины',
        eventDescription: 'Ждем всех желающих 31 января начало в 17-00ч.на обсуждение кинокартиные в клбуе "Фильм, фильм, фильм" будем болтать и пить чай в дружеской атмосфере ВХОД СВОБОДНЫЙ',
        eventCost: 'ДК "Звёздный"',
        eventImgSrc: require('../../assets/images/eventsImages/фильм-01.jpg'),
        eventsPreviewImg: require('../../assets/images/eventsImages/eventsPreviewImgs/фильм-02.jpg'),
    },
]