export const festivalsConfig = [
  {
    id: 1,
    title: 'Фестиваль художественного слова',
    name: 'Золотые слова Пушкина',
    info: 'Фестиваль проводится в рамках сотрудничества учреждений социально-культурной деятельности в г. Москве. Фестиваль приурочен к Дню памяти А. С. Пушкина Основная цель фестиваля – популяризация произведений А. С. Пушкина, привлечение читательского и общественного творчества к проблемам чтения, развития книжной культуры, к социальной и духовной составляющим литературного диалога.',
    backgroundImage: require('../../assets/images/festivalsImages/Pushkin.jpg'),
    place: 'ДК Звёздный',
    date: 'Февраль',
    age: '5 +',
    href: require('../../assets/docs/festivals/Положение - Пушкин 2025 (1).docx')
  },
  {
    id: 2,
    title: 'Фестиваль Народного Творчества',
    name: 'Семёновна',
    info: 'Открытый фестиваль народного творчества, посвящен памяти Заслуженного работника культуры Московской области Валентине Семеновне Решетниковой. В фестивале принимают участие коллективы народного творчества Москвы и Подмосковья',
    backgroundImage: require('../../assets/images/festivalsImages/semenovna-Fest.jpg'),
    place: 'ДК Звёздный',
    date: 'Март',
    age: '4 +',
    href: require('../../assets/docs/festivals/Семёновна-2023.doc')
  },
  {
    id: 3,
    title: 'Открытый театральный фестиваль – конкурс любительских театральных коллективов',
    name: ' "Красная линия"',
    info: 'Открытый театральный фестиваль  - конкурс «Красная линия» любительских театральных коллективов учреждений культуры города Москвы и соседних регионов проводится с 2010 года, ежегодно. Фестиваль посвящён памяти Елены Александровны Гущиной.    ',
    backgroundImage: require('../../assets/images/festivalsImages/red-line-Fest.jpg'),
    place: 'ДК Звёздный',
    date: 'Март',
    age: '5 +',
    href: require('../../assets/docs/festivals/RedLine2023.docx')
  },
  {
    id: 4,
    title: 'Фестиваль - конкурс музыкальных пародий',
    name: 'Точное попадание',
    info: '«Точное попадание» ежегодный конкурс музыкальных пародий проводится в первых числах апреля.',
    backgroundImage: require('../../assets/images/festivalsImages/Hit-fest.jpg'),
    place: 'ДК Звёздный',
    date: 'Апрель',
    age: '10 +',
    href: require('../../assets/docs/festivals/Точное попадание 2023-1.docx')
  },
  {
    id: 5,
    title: 'Ежегодная конкурсная программа',
    name: 'Поющий май',
    info: 'Смотр хоровых коллективов и ансамблей «Поющий май» проводится в рамках Музыкального Фестиваля «Новая Москва» на территории ТиНАО города Москвы. ',
    backgroundImage: require('../../assets/images/festivalsImages/Sings-may.jpg'),
    place: 'ДК Звёздный',
    date: 'Апрель',
    age: '0 +',
    href: require('../../assets/docs/festivals/Положение Поющий май 2024.pdf')
  },
  {
    id: 6,
    title: 'Фестиваль',
    name: 'Содружество',
    info: 'Фестиваль «Содружество» приурочен к Дню славянской письменности и культуры, на территории ТиНАО города Москвы',
    backgroundImage: require('../../assets/images/festivalsImages/united-Fest.jpg'),
    place: 'ДК Звёздный',
    date: 'Май',
    age: '4 +',
    href: require('../../assets/docs/festivals/Содружество 2023.docx')
  },
  {
    id: 7,
    title: 'Межрегиональный Духовно-Просветительский Праздник',
    name: 'Троицкие Гуляния',
    info: 'Праздник «Троицкие гуляния» проводится в формате народных гуляний. Основные цели праздника  - сохранение и развитие народных традиций,  популяризация фольклорных коллективов,  приобщение молодежи к художественному народному творчеству, привлечение широкой общественности к достижениям культуры Московского региона.',
    backgroundImage: require('../../assets/images/festivalsImages/troitsk-Fest.jpg'),
    place: 'Парк Победы',
    date: 'Июнь',
    age: '4 +',
    href: require('../../assets/docs/festivals/Положение Троица 2024.docx')
  },  
  {
    id: 8,
    title: 'Ежегодный конкурс - дефиле',
    name: 'Модное Детство',
    info: 'Конкурс - дефиле "Модное детство" проходит под девизом «Счастливое детство», посвящается дню защиты детей. Проводится, как праздник детства, задора и популяризации активности детской творческой самореализации.',
    backgroundImage: require('../../assets/images/festivalsImages/Modnoe-detstvo.jpg'),
    place: 'ДК Звёздный',
    date: 'Июнь',
    age: '3 +',
    href: require('../../assets/docs/festivals/Модное детство 2023.docx')
  },
  {
    id: 9,
    title: 'Ежегодная конкурсная программа',
    name: 'Серебряная осень',
    info: 'Фестиваль проводится в рамках сотрудничества учреждений социально-культурной деятельности поселения Краснопахорское в г. Москве. Основная цель фестиваля — популяризация произведений отечественных авторов эпохи «Серебряного века», привлечение читательского и общественного творчества к проблемам чтения, развития книжной культуры, к социальной и духовной составляющим литературного диалога.',
    backgroundImage: require('../../assets/images/festivalsImages/Silver-autmn.jpg'),
    place: 'ДК Звёздный',
    date: 'Октябрь',
    age: '5 +',
    href: require('../../assets/docs/festivals/Серебряная осень 2023.docx')
  },
  {
    id: 10,
    title: 'Фестиваль',
    name: 'Венок Дружбы',
    info: 'Фестиваль Венок дружбы» - это комплекс просветительских, досуговых, конкурсных мероприятий, отражающих темы многонациональности в мире, в том числе России и Москве. Фестиваль приурочен к Международному Дню толерантности.',
    backgroundImage: require('../../assets/images/festivalsImages/frendly-Fest.jpg'),
    place: 'ДК Звёздный',
    date: 'Октябрь',
    age: '4 +',
    href: require('../../assets/docs/festivals/Венок дружбы 2023.docx')
  },
  {
    id: 11,
    title: 'Ежегодная конкурсная программа',
    name: 'Звезда по имени Мама',
    info: 'Ежегодная конкурсная программа "Звезда по имени Мама". По традиции в ней участвуют мамы поселения Краснопахорское. На суд жюри и зрителей участницы конкурса представляют свои визитные карточки, творческие номера, работы декоративно-прикладного творчество себя и своих детей, и ответы на веселые вопросы.',
    backgroundImage: require('../../assets/images/festivalsImages/mother-star.jpg'),
    place: 'ДК Звёздный',
    date: 'Ноябрь',
    age: 'до 45',
    href: require('../../assets/docs/festivals/Звезда-по-имени-мама.doc')
  },
  {
    id: 12,
    title: 'Ежегодная конкурсная программа',
    name: 'Ёлочная игрушка',
    info: 'Целью конкурса является выявление лучшего образного и цветового решения елочной игрушки для новогодней елки. Задачи конкурса – создание праздничной атмосферы и вовлечение детей в творческий процесс по изготовлению авторской елочной игрушки.',
    backgroundImage: require('../../assets/images/festivalsImages/Crismas-tree-toy.jpg'),
    place: 'ДК Звёздный',
    date: 'Декабрь',
    age: '5 +',
    href: require('../../assets/docs/festivals/Положение ёлочная игрушка.pdf')
  }
]